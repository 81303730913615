import moment from 'moment';

export class PuncherEnums {
  static PuncherDirection = {
    ENTER: 'ENTER',
    EXIT: 'EXIT',
    BOTH: 'BOTH',
  };

  static PuncherJustificatory = {
    COFFEE_BREAK: 'COFFEE_BREAK',
    MEAL_SERVICE_USED: 'MEAL_SERVICE_USED',
    AVAILABILITY: 'AVAILABILITY',
    SMARTWORKING: 'SMARTWORKING',
  };

  static RegistryTemplateFieldType = {
    NUMBER: 'number',
    STRING: 'string',
    DATE: 'date',
    BOOLEAN: 'boolean',
  };

  static RegistryStandardFieldsGroupByPunching = {
    PUNCHINGFULLNAME: 'PUNCHINGFULLNAME',
    PUNCHINGNAME: 'PUNCHINGNAME',
    PUNCHINGSURNAME: 'PUNCHINGSURNAME',
    PUNCHINGDATE: 'PUNCHINGDATE',
    PUNCHINGDIRECTION: 'PUNCHINGDIRECTION',
    PUNCHINGBREAK: 'PUNCHINGBREAK',
    PUNCHINGMEAL: 'PUNCHINGMEAL',
    PUNCHINGAVAILABILITY: 'PUNCHINGAVAILABILITY',
    PUNCHINGSMARTWORKING: 'PUNCHINGSMARTWORKING',
  };

  static RegistryStandardFieldsGroupByDay = {
    PUNCHINGFULLNAME: 'PUNCHINGFULLNAME',
    PUNCHINGNAME: 'PUNCHINGNAME',
    PUNCHINGSURNAME: 'PUNCHINGSURNAME',
    PUNCHINGDATE: 'PUNCHINGDATE',
    PUNCHINGFIRSTENTER: 'PUNCHINGFIRSTENTER',
    PUNCHINGLASTEXIT: 'PUNCHINGLASTEXIT',
    PUNCHINGFULLTIME: 'PUNCHINGFULLTIME',
    PUNCHINGREALTIME: 'PUNCHINGREALTIME',
    PUNCHINGBREAKTIME: 'PUNCHINGBREAKTIME',
  };

  static RegistryStandardFieldsGroupByMonth = {
    PUNCHINGFULLNAME: 'PUNCHINGFULLNAME',
    PUNCHINGNAME: 'PUNCHINGNAME',
    PUNCHINGSURNAME: 'PUNCHINGSURNAME',
    PUNCHINGMONTHLYREALTIME: 'PUNCHINGMONTHLYREALTIME',
    PUNCHINGFIRSTDAY: 'PUNCHINGFIRSTDAY',
    PUNCHINGLASTDAY: 'PUNCHINGLASTDAY',
  };

  static RegistryStandardRepeatedFieldsGroupByMonth = {
    PUNCHINGFULLNAME: 'PUNCHINGFULLNAME',
    PUNCHINGNAME: 'PUNCHINGNAME',
    PUNCHINGSURNAME: 'PUNCHINGSURNAME',
    PUNCHINGDATE: 'PUNCHINGDATE',
    PUNCHINGFIRSTENTER: 'PUNCHINGFIRSTENTER',
    PUNCHINGLASTEXIT: 'PUNCHINGLASTEXIT',
    PUNCHINGFULLTIME: 'PUNCHINGFULLTIME',
    PUNCHINGREALTIME: 'PUNCHINGREALTIME',
    PUNCHINGBREAKTIME: 'PUNCHINGBREAKTIME',
  };

  static TemplateTypeGroupBy = {
    PUNCHING: 'PUNCHING',
    DAY: 'DAY',
    MONTH: 'MONTH',
  };

  static ValidationStatus = {
    VALIDATED: 'VALIDATED',
    REJECTED: 'REJECTED',
    TOBE_VALIDATED: 'TOBE_VALIDATED',
  };
}

export const PUNCHER_LIST_PAGE_SIZE = 10;
export const WORKING_USERS_PAGE_SIZE = 10;
export const RETRY_FETCH_SETTINGS_DELAY = 30 * 1000;
export const VACATIONS_VALIDATION_PAGE_SIZE = 10;
export const PUNCHINGS_VALIDATION_PAGE_SIZE = 10;
export const ABSENCE_LIST_PAGE_SIZE = 10;
export const GUEST_VISITS_PAGE_SIZE = 10;
export const CASSA_INTEGRAZIONE_LIST_PAGE_SIZE = 10;

export const adjustDay = (day) => moment(day).format('YYYYMMDD');

export const getValidationStatus = (confirmed, validated) => {
  return !validated
    ? {
        label: PuncherEnums.ValidationStatus.TOBE_VALIDATED,
        style: 'yn-td-bordered-yellow',
      }
    : confirmed
    ? {
        label: PuncherEnums.ValidationStatus.VALIDATED,
        style: 'yn-td-bordered-green',
      }
    : {
        label: PuncherEnums.ValidationStatus.REJECTED,
        style: 'yn-td-bordered-red',
      };
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import Icon from '../../../components/icons/Icon';
import {
  getTreeQuestionsById,
  isCustomFieldsEditing,
} from '../../../js/tickets/selectors';
import TicketsFieldsTreeChoice from './TicketsFieldsTreeChoice';
import { deleteTreeQuestionRequest } from '../../../js/tickets/actions';
import { showConfirmModal } from '../../../js/confirm/actions';
import TicketsFieldsEditQuestionModal from './TicketsFieldsEditQuestionModal';
import LinkButton from '../../../components/LinkButton';
import TicketsFieldsEditChoiceModal from './TicketsFieldsEditChoiceModal';

const messages = defineMessages({
  deleteConfirmText: {
    id: 'TicketsFieldsTreeQuestion.deleteConfirmText',
    defaultMessage:
      'If you remove this question will be removed everything starting from here. Do you want to continue?',
  },
  addQuestion: {
    id: 'TicketsFieldsTreeQuestion.addQuestion',
    defaultMessage: 'Add a question',
  },
  editQuestion: {
    id: 'TicketsFieldsTreeQuestion.editQuestion',
    defaultMessage: 'Edit question',
  },
  removeQuestion: {
    id: 'TicketsFieldsTreeQuestion.removeQuestion',
    defaultMessage: 'Remove question',
  },
  addChoice: {
    id: 'TicketsFieldsTreeQuestion.addChoice',
    defaultMessage: 'Add a choice',
  },
});

class TicketsFieldsTreeQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showActions: true,
      showChoices: false,
      choiceModalOpen: false,
      //  newQuestionModalOpen: false,
      editQuestionModalOpen: false,
    };
  }

  handleActionsVisibility = () => {
    this.setState({
      showActions: true,
    });
  };

  showChoices = (show) => {
    this.setState({
      showChoices: show,
    });
  };

  choiceModalToggle = () => {
    const { choiceModalOpen } = this.state;
    this.setState({ choiceModalOpen: !choiceModalOpen });
  };

  /* newQuestionModalToggle = () => {
    const { newQuestionModalOpen } = this.state;
    this.setState({ newQuestionModalOpen: !newQuestionModalOpen });
  };
  */

  editQuestionModalToggle = () => {
    const { editQuestionModalOpen } = this.state;
    this.setState({ editQuestionModalOpen: !editQuestionModalOpen });
  };

  handleDelete = () => {
    const {
      confirm,
      deleteQuestion,
      questionId,
      treeId,
      choiceParent,
      whatsapp,
      intl: { formatMessage },
    } = this.props;

    confirm({
      message: formatMessage(messages.deleteConfirmText),
      modalAction: deleteQuestion,
      actionParams: {
        treeId,
        questionId,
        choiceParent,
        whatsapp,
      },
    });
  };

  render() {
    const {
      questionId,
      questionsById,
      showActions,
      choiceParent,
      // newQuestionOrder,
      treeId,
      whatsapp,
      intl: { formatMessage },
    } = this.props;
    const { showChoices, editQuestionModalOpen, choiceModalOpen } = this.state;
    const question = questionsById && questionsById[questionId];
    const choices = question && question.choices;
console.log('YEAH----------->', whatsapp)
    return (
      <li>
        {question && (
          <>
            {choices &&
              choices.length > 0 &&
              (showChoices ? (
                <span onClick={() => this.showChoices(false)}>
                  <Icon name="arrow-down-10-filled" width={12} height={12} />
                </span>
              ) : (
                <span onClick={() => this.showChoices(true)}>
                  <Icon name="arrow-right-10-filled" width={12} height={12} />
                </span>
              ))}
            <span
              className="d-inline-block font-weight-bold ml-1"
              onMouseEnter={() => this.handleActionsVisibility(true)}
              onMouseLeave={() => this.handleActionsVisibility(false)}
              style={{ position: 'relative', top: '-1px' }}
            >
              {question.label}
            </span>
            {showActions && (
              <>
                <span className="ml-2" onClick={this.editQuestionModalToggle}>
                  <Icon
                    name="pencil-write-2"
                    color="var(--yn-blue-dark)"
                    width={20}
                    height={20}
                    style={{ top: '4px' }}
                  >
                    {formatMessage(messages.editQuestion)}
                  </Icon>
                </span>
                <span className="ml-2" onClick={this.handleDelete}>
                  <Icon
                    name="bin-1"
                    color="var(--yn-blue-dark)"
                    width={20}
                    height={20}
                    style={{ top: '4px' }}
                  >
                    {formatMessage(messages.removeQuestion)}
                  </Icon>
                </span>
              </>
            )}
            {editQuestionModalOpen && (
              <TicketsFieldsEditQuestionModal
                question={question}
                isOpen={editQuestionModalOpen}
                onClose={this.editQuestionModalToggle}
                order={question.order}
                choiceParent={choiceParent}
                whatsapp={whatsapp}
              />
            )}
            {showChoices && choices && choices.length > 0 && (
              <ul className="list-unstyled">
                {choices.map((choice) => (
                  <TicketsFieldsTreeChoice
                    key={choice.choiceId}
                    treeId={treeId}
                    questionId={questionId}
                    choice={choice}
                    choiceParent={question.choiceParent || choiceParent}
                    whatsapp={whatsapp}
                  />
                ))}
                {showActions && (
                  <li>
                    <LinkButton onClick={this.choiceModalToggle}>
                      {formatMessage(messages.addChoice)}
                    </LinkButton>
                    {choiceModalOpen && (
                      <TicketsFieldsEditChoiceModal
                        isOpen={choiceModalOpen}
                        treeId={treeId}
                        questionId={questionId}
                        onClose={this.choiceModalToggle}
                        choiceParent={question.choiceParent}
                        whatsapp={whatsapp}
                      />
                    )}
                  </li>
                )}
              </ul>
            )}
          </>
        )}
      </li>
    );
  }
}

TicketsFieldsTreeQuestion.propTypes = {
  treeId: PropTypes.number.isRequired,
  questionId: PropTypes.number.isRequired,
  choiceParent: PropTypes.number,
  whatsapp: PropTypes.bool,
};

TicketsFieldsTreeQuestion.defaultProps = {
  choiceParent: null,
  whatsapp: false,
};

function mapStateToProps(state) {
  return {
    questionsById: getTreeQuestionsById(state),
    showActions: isCustomFieldsEditing(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    deleteQuestion: deleteTreeQuestionRequest,
    confirm: showConfirmModal,
  })(TicketsFieldsTreeQuestion)
);

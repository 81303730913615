import {
  deleteVirtualOperatorMessageFailure,
  deleteVirtualOperatorMessageSuccess,
  fetchVirtualOperatorMessagesFailure,
  fetchVirtualOperatorMessagesRequest,
  fetchVirtualOperatorMessagesSuccess,
  fetchVirtualOperatorOfficesFailure,
  fetchVirtualOperatorOfficesSuccess,
  fetchVirtualOperatorUsersFailure,
  fetchVirtualOperatorUsersSuccess,
  saveEnterpriseFestivitiesFailure,
  saveEnterpriseFestivitiesSuccess,
  saveOpeningFailure,
  saveOpeningSuccess,
  saveVirtualOperatorMessageFailure,
  saveVirtualOperatorMessageSuccess,
  saveVirtualOperatorOfficeFailure,
  saveVirtualOperatorOfficeSuccess,
  saveVirtualOperatorUserFailure,
  saveVirtualOperatorUserSuccess,
} from './actions';
import api from '../api';
import {
  DELETE_VIRTUAL_OPERATOR_MESSAGE_REQUEST,
  FETCH_VIRTUAL_OPERATOR_MESSAGES_REQUEST,
  FETCH_VIRTUAL_OPERATOR_OFFICES_REQUEST,
  FETCH_VIRTUAL_OPERATOR_USERS_REQUEST,
  SAVE_ENTERPRISE_FESTIVITIES_REQUEST,
  SAVE_OPENING_REQUEST,
  SAVE_VIRTUAL_OPERATOR_MESSAGE_REQUEST,
  SAVE_VIRTUAL_OPERATOR_OFFICE_REQUEST,
  SAVE_VIRTUAL_OPERATOR_USER_REQUEST,
} from './types';
import { call, put, takeLatest } from 'redux-saga/effects';
import { checkApiError, checkApiResponse } from '../rootSaga';
import { fetchPbxQueues } from '../phone/sagas';
import { fetchPbxQueuesRequest } from '../phone/actions';

export function* saveEnterpriseFestivities(action) {
  try {
    const res = yield call(
      api.virtualOperator.saveEnterpriseFestivities,
      action.data
    );
    yield call(checkApiResponse, res);
    yield put(saveEnterpriseFestivitiesSuccess(action.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveEnterpriseFestivitiesFailure(error));
  }
}

export function* saveOpening(action) {
  try {
    const res = yield call(api.virtualOperator.saveOpening, action.data);
    yield call(checkApiResponse, res);
    yield put(saveOpeningSuccess(action.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveOpeningFailure(error));
  }
}

export function* fetchUsers(action) {
  try {
    const res = yield call(api.virtualOperator.getUsers, action.params);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        fetchVirtualOperatorUsersSuccess({
          users: [],
          total: 0,
        })
      );
    } else {
      yield put(
        fetchVirtualOperatorUsersSuccess({
          users: res.data.data,
          total: res.data.total,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchVirtualOperatorUsersFailure(error));
  }
}

export function* saveUser(action) {
  try {
    const res = yield call(api.virtualOperator.saveUser, action.data);
    yield call(checkApiResponse, res);
    yield put(saveVirtualOperatorUserSuccess(action.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveVirtualOperatorUserFailure(error));
  }
}

export function* fetchOffices(action) {
  try {
    const res = yield call(api.virtualOperator.getOffices, action.params);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        fetchVirtualOperatorOfficesSuccess({
          offices: [],
          total: 0,
        })
      );
    } else {
      yield put(
        fetchVirtualOperatorOfficesSuccess({
          offices: res.data.data,
          total: res.data.total,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchVirtualOperatorOfficesFailure(error));
  }
}

export function* saveOffice(action) {
  try {
    if (action.data.id) {
      const res = yield call(api.virtualOperator.editOffice, action.data);
      yield call(checkApiResponse, res);
      yield put(saveVirtualOperatorOfficeSuccess(action.data));
    } else {
      const res = yield call(api.virtualOperator.insertOffice, action.data);
      yield call(checkApiResponse, res);
      yield put(
        saveVirtualOperatorOfficeSuccess({
          id: res.data.id,
          data: action.data.data,
        })
      );
    }
    yield put(fetchPbxQueuesRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveVirtualOperatorOfficeFailure(error));
  }
}

export function* fetchMessages(action) {
  try {
    const res = yield call(api.virtualOperator.getMessages, action.params);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        fetchVirtualOperatorMessagesSuccess({
          messages: [],
          total: 0,
        })
      );
    } else {
      yield put(
        fetchVirtualOperatorMessagesSuccess({
          messages: res.data.data,
          total: res.data.total,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchVirtualOperatorMessagesFailure(error));
  }
}

export function* saveMessage(action) {
  try {
    if (action.data.id) {
      const res = yield call(api.virtualOperator.editMessage, action.data);
      yield call(checkApiResponse, res);
      yield put(saveVirtualOperatorMessageSuccess(action.data));
    } else {
      const res = yield call(api.virtualOperator.insertMessage, action.data);
      yield call(checkApiResponse, res);
      yield put(
        saveVirtualOperatorMessageSuccess({
          id: res.data.id,
          data: action.data.data,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveVirtualOperatorMessageFailure(error));
  }
}

export function* deleteMessage(action) {
  try {
    const res = yield call(api.virtualOperator.deleteMessage, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteVirtualOperatorMessageSuccess(action.id));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteVirtualOperatorMessageFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(
    SAVE_ENTERPRISE_FESTIVITIES_REQUEST,
    saveEnterpriseFestivities
  );
  yield takeLatest(SAVE_OPENING_REQUEST, saveOpening);
  yield takeLatest(FETCH_VIRTUAL_OPERATOR_USERS_REQUEST, fetchUsers);
  yield takeLatest(SAVE_VIRTUAL_OPERATOR_USER_REQUEST, saveUser);
  yield takeLatest(FETCH_VIRTUAL_OPERATOR_OFFICES_REQUEST, fetchOffices);
  yield takeLatest(SAVE_VIRTUAL_OPERATOR_OFFICE_REQUEST, saveOffice);
  yield takeLatest(FETCH_VIRTUAL_OPERATOR_MESSAGES_REQUEST, fetchMessages);
  yield takeLatest(SAVE_VIRTUAL_OPERATOR_MESSAGE_REQUEST, saveMessage);
  yield takeLatest(DELETE_VIRTUAL_OPERATOR_MESSAGE_REQUEST, deleteMessage);
}

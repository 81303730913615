import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../../components/formControls/Button';
import { saveTreeChoiceRequest } from '../../../js/tickets/actions';
import {
  getSaveTreeChoiceError,
  getSaveTreeChoiceLoaded,
} from '../../../js/tickets/selectors';
import Input from '../../../components/formControls/Input';
import Utils from '../../../js/lib/utils';
import ToastMessage from '../../../components/messages/ToastMessage';

const messages = defineMessages({
  titleInsert: {
    id: 'TicketsFieldsEditChoiceModal.titleInsert',
    defaultMessage: 'Add a new choice',
  },
  titleEdit: {
    id: 'TicketsFieldsEditChoiceModal.titleEdit',
    defaultMessage: 'Modify the choice',
  },
  saveButton: {
    id: 'TicketsFieldsEditChoiceModal.saveButton',
    defaultMessage: 'Save',
  },
  cancelButton: {
    id: 'TicketsFieldsEditChoiceModal.cancelButton',
    defaultMessage: 'Cancel',
  },
  errorMissingChoice: {
    id: 'TicketsFieldsEditChoiceModal.errorMissingChoice',
    defaultMessage: 'Please insert a choice',
  },
});

class TicketsFieldsEditChoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: undefined,
      errors: {},
    };
  }

  componentDidUpdate(prevprops) {
    const { saving, error, onClose } = this.props;
    if (prevprops.saving && !saving && !error) {
      onClose();
    }
  }

  handleChoice = (e) => {
    this.setState({ choice: e.target.value, errors: { choice: null } });
  };

  arrangeChoice = () =>
    Utils.getValorizedVariable(this.state.choice, this.props.choice, '');

  onSave = () => {
    const {
      questionId,
      treeId,
      choiceId,
      choiceParent,
      whatsapp,
      save,
      intl: { formatMessage },
    } = this.props;
    const errors = {};
    const choice = this.arrangeChoice();
    if (!choice) errors.choice = formatMessage(messages.errorMissingChoice);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      save({ questionId, treeId, choiceId, choiceParent, value: choice, whatsapp });
    }
  };

  render() {
    const {
      choiceId,
      whatsapp,
      isOpen,
      onClose,
      error,
      saving,
      intl: { formatMessage },
    } = this.props;

    const { errors } = this.state;

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          {formatMessage(choiceId ? messages.titleEdit : messages.titleInsert)}
        </ModalHeader>
        <ModalBody>
          {error && <ToastMessage type="danger" messageCode={error || 400} />}
          <div className="row">
            <div className="col mb-2">
              <Input
                type="text"
                onChange={this.handleChoice}
                value={this.arrangeChoice()}
                name="ticketChoice"
                id="ticketChoice"
                error={errors.choice}
                maxLength={whatsapp ? 24 : null}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-primary"
            text={formatMessage(messages.saveButton)}
            loading={saving}
            onClick={this.onSave}
          />
          <Button
            className="btn btn-outline-primary"
            text={formatMessage(messages.cancelButton)}
            onClick={onClose}
          />
        </ModalFooter>
      </Modal>
    );
  }
}

TicketsFieldsEditChoiceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  choice: PropTypes.string,
  choiceId: PropTypes.number,
  choiceParent: PropTypes.number.isRequired,
  whatsapp: PropTypes.bool,
};

TicketsFieldsEditChoiceModal.defaultProps = {
  isOpen: false,
  choiceId: null,
  choice: '',
  whatsapp: false,
};

function mapStateToProps(state) {
  return {
    saving: !getSaveTreeChoiceLoaded(state),
    error: getSaveTreeChoiceError(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, { save: saveTreeChoiceRequest })(
    TicketsFieldsEditChoiceModal
  )
);

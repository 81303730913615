import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../../components/formControls/Button';
import Label from '../../../components/formControls/Label';
import Input from '../../../components/formControls/Input';
import Select2 from '../../../components/formControls/Select2';
import { TicketEnums } from '../../../js/tickets/TicketUtils';
import Utils from '../../../js/lib/utils';
import { saveTreeQuestionRequest } from '../../../js/tickets/actions';
import {
  getTreeId,
  getSaveTreeQuestionLoaded,
  getSaveTreeQuestionError,
} from '../../../js/tickets/selectors';
import TicketsFieldsEditChoicesList from './TicketsFieldsEditChoicesList';
import ToastMessage from '../../../components/messages/ToastMessage';

const messages = defineMessages({
  titleInsert: {
    id: 'TicketsFieldsEditQuestionModal.titleInsert',
    defaultMessage: 'Add a new question',
  },
  titleEdit: {
    id: 'TicketsFieldsEditQuestionModal.titleEdit',
    defaultMessage: 'Modify the question',
  },
  saveButton: {
    id: 'TicketsFieldsEditQuestionModal.saveButton',
    defaultMessage: 'Save',
  },
  cancelButton: {
    id: 'TicketsFieldsEditQuestionModal.cancelButton',
    defaultMessage: 'Cancel',
  },
  label: {
    id: 'TicketsFieldsEditQuestionModal.label',
    defaultMessage: 'Label',
  },
  type: {
    id: 'TicketsFieldsEditQuestionModal.type',
    defaultMessage: 'Type',
  },
  typePlaceholder: {
    id: 'TicketsFieldsEditQuestionModal.typePlaceholder',
    defaultMessage: 'Select a type...',
  },
  isMandatory: {
    id: 'TicketsFieldsEditQuestionModal.isMandatory',
    defaultMessage: 'Is mandatory?',
  },
  help: {
    id: 'TicketsFieldsEditQuestionModal.help',
    defaultMessage: 'Suggestions',
  },
  choices: {
    id: 'TicketsFieldsEditQuestionModal.choices',
    defaultMessage: 'Options',
  },
  addChoice: {
    id: 'TicketsFieldsEditQuestionModal.addChoice',
    defaultMessage: 'Add an option',
  },
  removeChoice: {
    id: 'TicketsFieldsEditQuestionModal.removeChoice',
    defaultMessage: 'Remove this option',
  },
  errorMissingLabel: {
    id: 'TicketsFieldsEditQuestionModal.errorMissingLabel',
    defaultMessage: 'Please insert a label',
  },
  errorMissingType: {
    id: 'TicketsFieldsEditQuestionModal.errorMissingType',
    defaultMessage: 'Please select a type',
  },
  errorMissingChoices: {
    id: 'TicketsFieldsEditQuestionModal.errorMissingChoices',
    defaultMessage: 'Please insert at least one choice',
  },
  fieldTEXT: {
    id: 'TicketsFieldsEditQuestionModal.fieldTEXT',
    defaultMessage: 'Text',
  },
  fieldDATE: {
    id: 'TicketsFieldsEditQuestionModal.fieldDATE',
    defaultMessage: 'Date',
  },
  fieldFILE: {
    id: 'TicketsFieldsEditQuestionModal.fieldFILE',
    defaultMessage: 'File upload',
  },
  fieldSELECT: {
    id: 'TicketsFieldsEditQuestionModal.fieldSELECT',
    defaultMessage: 'Select',
  },
  fieldRADIO: {
    id: 'TicketsFieldsEditQuestionModal.fieldRADIO',
    defaultMessage: 'Radio buttons',
  },
  fieldCHECKBOX: {
    id: 'TicketsFieldsEditQuestionModal.fieldCHECKBOX',
    defaultMessage: 'Checkbox',
  },
  fieldMAP: {
    id: 'TicketsFieldsEditQuestionModal.fieldMAP',
    defaultMessage: 'Map',
  },
  fieldTEXTwhatsapp: {
    id: 'TicketsFieldsEditQuestionModal.fieldTEXTwhatsapp',
    defaultMessage: 'Text',
  },
  fieldDATEwhatsapp: {
    id: 'TicketsFieldsEditQuestionModal.fieldDATEwhatsapp',
    defaultMessage: 'Date',
  },
  fieldFILEwhatsapp: {
    id: 'TicketsFieldsEditQuestionModal.fieldFILEwhatsapp',
    defaultMessage: 'File upload',
  },
  fieldSELECTwhatsapp: {
    id: 'TicketsFieldsEditQuestionModal.fieldSELECTwhatsapp',
    defaultMessage: 'List',
  },
  fieldRADIOwhatsapp: {
    id: 'TicketsFieldsEditQuestionModal.fieldRADIOwhatsapp',
    defaultMessage: 'Rapid answers',
  },
  fieldMAPwhatsapp: {
    id: 'TicketsFieldsEditQuestionModal.fieldMAPwhatsapp',
    defaultMessage: 'Map',
  },
});

class TicketsFieldsEditQuestionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        label: undefined,
        type: undefined,
        mandatory: undefined,
        help: undefined,
        choices: undefined,
      },
      errors: {},
    };
  }

  componentDidUpdate(prevprops) {
    const { saving, error, onClose } = this.props;
    if (prevprops.saving && !saving && !error) {
      onClose();
    }
  }

  handleLabel = (e) => {
    this.setState({
      data: { ...this.state.data, label: e.target.value },
      errors: { ...this.state.errors, label: null },
    });
  };

  handleType = (selected) => {
    this.setState({
      data: { ...this.state.data, type: selected ? selected.value : '' },
      errors: { ...this.state.errors, type: null },
    });
  };

  handleMandatory = (e) => {
    this.setState({
      data: { ...this.state.data, mandatory: e.target.checked },
    });
  };

  handleHelp = (e) => {
    this.setState({ data: { ...this.state.data, help: e.target.value } });
  };

  handleChoicesChange = (choices) => {
    this.setState({
      data: { ...this.state.data, choices },
      errors: { ...this.state.errors, choices: null },
    });
  };

  arrangeLabel = () =>
    Utils.getValorizedVariable(
      this.state.data.label,
      this.props.question.label,
      ''
    );

  arrangeType = () =>
    Utils.getValorizedVariable(
      this.state.data.type,
      this.props.question.type,
      ''
    );

  arrangeMandatory = () =>
    Utils.getValorizedVariable(
      this.state.data.mandatory,
      this.props.question.mandatory,
      false
    );

  arrangeHelp = () =>
    Utils.getValorizedVariable(
      this.state.data.help,
      this.props.question.help,
      ''
    );

  arrangeChoices = () =>
    Utils.getValorizedVariable(
      this.state.data.choices,
      this.props.question.choices,
      ['']
    );

  validate = (data) => {
    const {
      question,
      intl: { formatMessage },
    } = this.props;
    const errors = {};
    if (!data.label) errors.label = formatMessage(messages.errorMissingLabel);
    if (!data.type && !question.questionId)
      errors.type = formatMessage(messages.errorMissingType);
    if (
      (data.type === TicketEnums.TicketQuestionTypes.SELECT ||
        data.type === TicketEnums.TicketQuestionTypes.RADIO ||
        data.type === TicketEnums.TicketQuestionTypes.CHECKBOX) &&
      (!this.arrangeChoices() ||
        this.arrangeChoices().length === 0 ||
        this.arrangeChoices().filter((c) => c !== '' && c.value !== '')
          .length === 0)
    ) {
      errors.choices = formatMessage(messages.errorMissingChoices);
    }

    return errors;
  };

  onSave = () => {
    const { treeId, question, choiceParent, whatsapp, order, save } = this.props;
    const { questionId } = question;
    const data = {
      whatsapp,
      treeId,
      label: this.arrangeLabel(),
      mandatory: this.arrangeMandatory(),
      order,
      choiceParent,
    };
    if (questionId) {
      data.questionId = questionId;
    } else {
      data.type = this.arrangeType();
      if (
        this.arrangeChoices() &&
        this.arrangeChoices().length > 0 &&
        !(this.arrangeChoices().length === 1 && this.arrangeChoices()[0] === '')
      )
        data.choices = this.state.data.choices.map((choice) => choice.value);
    }
    if (this.arrangeHelp()) {
      data.help = this.arrangeHelp();
    }
    const errors = this.validate(data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      save(data);
    }
  };

  render() {
    const {
      question,
      isOpen,
      onClose,
      error,
      saving,
      whatsapp,
      intl: { formatMessage },
    } = this.props;
    const { questionId } = question;
    const { errors } = this.state;
    const typesOptions = Object.keys(TicketEnums.TicketQuestionTypes).filter(item => !whatsapp || TicketEnums.TicketQuestionTypes[item] !== TicketEnums.TicketQuestionTypes.CHECKBOX).map(
      (key) => ({
        value: TicketEnums.TicketQuestionTypes[key],
        label: messages[`field${TicketEnums.TicketQuestionTypes[key]}${whatsapp ? 'whatsapp' : ''}`]
          ? formatMessage(
              messages[`field${TicketEnums.TicketQuestionTypes[key]}${whatsapp ? 'whatsapp' : ''}`]
            )
          : TicketEnums.TicketQuestionTypes[key],
      })
    );

    const chosenType = this.arrangeType();
    const typesSelected = typesOptions.filter(
      (o) => o.value === chosenType
    );    console.log('--------------->', whatsapp)
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          {formatMessage(
            questionId ? messages.titleEdit : messages.titleInsert
          )}
        </ModalHeader>
        <ModalBody>
          {error && <ToastMessage type="danger" messageCode={error || 400} />}          
          <div className="row mb-1">
            <div className="col">
              <Label for="ticketType" mandatory>
                {formatMessage(messages.type)}
              </Label>
              <Select2
                isClearable
                id="ticketType"
                name="ticketType"
                value={typesSelected}
                onChange={this.handleType}
                options={typesOptions}
                isDisabled={!!questionId}
                error={errors.type}
                placeholder={formatMessage(messages.typePlaceholder)}
                style={
                  questionId && {
                    control: { background: 'var(--yn-gray-200)' },
                  }
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col mb-2">
              <Label for="ticketLabel" mandatory>
                {formatMessage(messages.label)}
              </Label>
              <Input
                type="text"
                onChange={this.handleLabel}
                value={this.arrangeLabel()}
                name="ticketLabel"
                id="ticketLabel"
                error={errors.label}
                maxLength={whatsapp ? (!chosenType || (chosenType === TicketEnums.TicketQuestionTypes.SELECT &&
                  chosenType === TicketEnums.TicketQuestionTypes.RADIO)) ? 1024 :60 : null}
              />
            </div>
          </div>
          {(chosenType === TicketEnums.TicketQuestionTypes.SELECT ||
            chosenType === TicketEnums.TicketQuestionTypes.RADIO ||
            chosenType ===
              TicketEnums.TicketQuestionTypes.CHECKBOX) && (
            <TicketsFieldsEditChoicesList
              choices={this.arrangeChoices()}
              type={chosenType}
              onChange={this.handleChoicesChange}
              isDisabled={!!questionId}
              error={errors.choices}
              whatsapp={whatsapp}
            />
          )}
          <div className="row mt-3 mb-2">
            <div className="col">
              <div className="custom-control custom-checkbox custom-control-inline">
                <Label
                  htmlFor="isMandatory"
                  style={{ position: 'relative', left: '-1.5rem' }}
                >
                  {formatMessage(messages.isMandatory)}
                </Label>
                <input
                  type="checkbox"
                  id="isMandatory"
                  name="isMandatory"
                  checked={this.arrangeMandatory()}
                  className="custom-control-input"
                  onChange={this.handleMandatory}
                />
                <label
                  className="custom-control-label ml-3"
                  htmlFor="isMandatory"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col mb-2">
              <Label for="ticketHelp">{formatMessage(messages.help)}</Label>
              <Input
                type="text"
                onChange={this.handleHelp}
                value={this.arrangeHelp()}
                name="ticketHelp"
                id="ticketHelp"
                maxLength={whatsapp && (chosenType === TicketEnums.TicketQuestionTypes.SELECT ||
                  chosenType === TicketEnums.TicketQuestionTypes.RADIO)? 60 : null}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-primary"
            text={formatMessage(messages.saveButton)}
            onClick={this.onSave}
            loading={saving}
          />
          <Button
            className="btn btn-outline-primary"
            text={formatMessage(messages.cancelButton)}
            onClick={onClose}
          />
        </ModalFooter>
      </Modal>
    );
  }
}

TicketsFieldsEditQuestionModal.propTypes = {
  isOpen: PropTypes.bool,
  question: PropTypes.object,
  order: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  choiceParent: PropTypes.number,
  whatsapp: PropTypes.bool,
};

TicketsFieldsEditQuestionModal.defaultProps = {
  isOpen: false,
  question: {},
  order: 0,
  choiceParent: null,
  whatsapp: false,
};

function mapStateToProps(state) {
  return {
    saving: !getSaveTreeQuestionLoaded(state),
    error: getSaveTreeQuestionError(state),
    treeId: getTreeId(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, { save: saveTreeQuestionRequest })(
    TicketsFieldsEditQuestionModal
  )
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { hasGrants } from '../../../../js/me/selectors';
import ToolboxIcon from '../../../../components/icons/ToolboxIcon';
import PbxVirtualOperatorOfficeModalEdit from './PbxVirtualOperatorOfficeModalEdit';
import Popover from '../../../../components/Popover';
import { getCalendarFestivitiesSettings } from '../../../../js/calendar/selectors';
import Utils from '../../../../js/lib/utils';
import { getVirtualOperatorMessages } from '../../../../js/virtualOperator/selectors';

const messages = defineMessages({
  showOpening: {
    id: 'PbxVirtualOperatorOfficeRow.tooltip.showOpening',
    defaultMessage: 'Show opening hours',
  },
  showFestivities: {
    id: 'PbxVirtualOperatorOfficeRow.tooltip.showFestivities',
    defaultMessage: 'Show festivities',
  },
  CAPODANNO: {
    id: 'PbxVirtualOperatorOfficeRow.CAPODANNO.label',
    defaultMessage: 'First of the year',
  },
  EPIFANIA: {
    id: 'PbxVirtualOperatorOfficeRow.EPIFANIA.label',
    defaultMessage: 'Epiphany',
  },
  LIBERAZIONE: {
    id: 'PbxVirtualOperatorOfficeRow.LIBERAZIONE.label',
    defaultMessage: 'Freedom day',
  },
  LAVORATORI: {
    id: 'PbxVirtualOperatorOfficeRow.LAVORATORI.label',
    defaultMessage: 'Workers day',
  },
  REPUBBLICA: {
    id: 'PbxVirtualOperatorOfficeRow.REPUBBLICA.label',
    defaultMessage: 'Republic day',
  },
  FERRAGOSTO: {
    id: 'PbxVirtualOperatorOfficeRow.FERRAGOSTO.label',
    defaultMessage: 'Mid-August',
  },
  OGNISSANTI: {
    id: 'PbxVirtualOperatorOfficeRow.OGNISSANTI.label',
    defaultMessage: 'All Saints',
  },
  IMMACOLATA: {
    id: 'PbxVirtualOperatorOfficeRow.IMMACOLATA.label',
    defaultMessage: 'Immaculate Conception',
  },
  NATALE: {
    id: 'PbxVirtualOperatorOfficeRow.NATALE.label',
    defaultMessage: 'Christmas',
  },
  SANTO_STEFANO: {
    id: 'PbxVirtualOperatorOfficeRow.SANTO_STEFANO.label',
    defaultMessage: 'Day after Christmas',
  },
  PASQUA: {
    id: 'PbxVirtualOperatorOfficeRow.PASQUA.label',
    defaultMessage: 'Easter',
  },
  PASQUETTA: {
    id: 'PbxVirtualOperatorOfficeRow.PASQUETTA.label',
    defaultMessage: 'Day after Easter',
  },
  allSelected: {
    id: 'PbxVirtualOperatorOfficeRow.allSelected',
    defaultMessage: 'All selected',
  },
  standardFestivities: {
    id: 'PbxVirtualOperatorOfficeRow.standardFestivities.label',
    defaultMessage: 'National Festivities',
  },
  customFestivities: {
    id: 'PbxVirtualOperatorOfficeRow.customFestivities.label',
    defaultMessage: 'Added Festivities',
  },
  0: {
    id: 'PbxVirtualOperatorOfficeRow.0',
    defaultMessage: 'Sunday',
  },
  1: {
    id: 'PbxVirtualOperatorOfficeRow.1',
    defaultMessage: 'Monday',
  },
  2: {
    id: 'PbxVirtualOperatorOfficeRow.2',
    defaultMessage: 'Tuesday',
  },
  3: {
    id: 'PbxVirtualOperatorOfficeRow.3',
    defaultMessage: 'Wednesday',
  },
  4: {
    id: 'PbxVirtualOperatorOfficeRow.4',
    defaultMessage: 'Thursday',
  },
  5: {
    id: 'PbxVirtualOperatorOfficeRow.5',
    defaultMessage: 'Friday',
  },
  6: {
    id: 'PbxVirtualOperatorOfficeRow.6',
    defaultMessage: 'Saturday',
  },
  editOffice: {
    id: 'PbxVirtualOperatorOfficeRow.tooltip.editOffice',
    defaultMessage: 'Edit',
  },
  BLIND: {
    id: 'PbxVirtualOperatorOfficeRow.BLIND',
    defaultMessage: 'Blind',
  },
  SUPERVISED: {
    id: 'PbxVirtualOperatorOfficeRow.SUPERVISED',
    defaultMessage: 'Supervised',
  },
  CONSULTATION: {
    id: 'PbxVirtualOperatorOfficeRow.CONSULTATION',
    defaultMessage: 'Consultation',
  },
  message: {
    id: 'PbxVirtualOperatorOfficeRow.message.label',
    defaultMessage: 'Message',
  },
});

class PbxVirtualOperatorOfficeRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalEditOpen: false,
      popoverOpeningOpen: false,
      popoverFestivitiesOpen: false,
    };
  }

  toggleEditModal = () => {
    const { modalEditOpen } = this.state;
    this.setState({
      modalEditOpen: !modalEditOpen,
    });
  };

  toggleOpeningPopover = () => {
    this.setState({
      popoverOpeningOpen: !this.state.popoverOpeningOpen,
    });
  };

  toggleFestivitiesPopover = () => {
    this.setState({
      popoverFestivitiesOpen: !this.state.popoverFestivitiesOpen,
    });
  };

  render() {
    const {
      office,
      customerFestivities,
      createdMessages,
      canEdit,
      intl: { formatMessage },
    } = this.props;

    const selectedOpening = [];

    if (office.opening) {
      for (let i = 0; i < 7; i += 1) {
        if (office.opening[i] && office.opening[i].entrance1) {
          let message = `${office.opening[i].entrance1}-${office.opening[i].exit1}`;
          if (office.opening[i].entrance2) {
            message += `, ${office.opening[i].entrance2}-${office.opening[i].exit2}`;
          }
          selectedOpening.push(message);
        } else {
          selectedOpening.push(null);
        }
      }
    }

    const selectedStandardFestivites = [];
    const selectedCustomFestivites = [];
    const standardFestivities = Utils.retrieveFestivities(
      [],
      [],
      new Date().getFullYear()
    );
    if (office.festivities) {
      if (
        !standardFestivities.some(
          (item) =>
            !office.festivities.standard ||
            !office.festivities.standard.includes(item.description)
        )
      ) {
        selectedStandardFestivites.push(formatMessage(messages.allSelected));
      } else {
        office.festivities.standard &&
          office.festivities.standard.forEach((festivity) => {
            selectedStandardFestivites.push(
              formatMessage(
                messages[
                  standardFestivities.find((f) => f.description === festivity)
                    .description
                ]
              )
            );
          });
      }
      office.festivities.custom &&
        office.festivities.custom.forEach((festivity) => {
          if (
            customerFestivities.added &&
            customerFestivities.added.find((f) => f.description === festivity)
          ) {
            selectedCustomFestivites.push(
              customerFestivities.added.find((f) => f.description === festivity)
                .description
            );
          }
        });
    }

    return (
      <>
        <tr>
          <td>{`${office.id}${office.name && office.name !== office.id ?` (${office.name})`:''}`}</td>
          <td>{office.description}</td>
          <td>{office.pronunciation}</td>
          <td>{office.transfer && formatMessage(messages[office.transfer])}</td>
          <td>
            {(selectedStandardFestivites.length > 0 ||
              selectedCustomFestivites.length > 0) && (
              <>
                <span
                  onClick={this.toggleFestivitiesPopover}
                  id={`FestivitiesPopover_${office.route}`}
                >
                  <ToolboxIcon
                    name="file-view-1"
                    className="p-2"
                    style={{ top: '2px' }}
                  >
                    {formatMessage(messages.showFestivities)}
                  </ToolboxIcon>
                </span>
                <Popover
                  isOpen={this.state.popoverFestivitiesOpen}
                  toggle={this.toggleFestivitiesPopover}
                  target={`FestivitiesPopover_${office.route}`}
                >
                  {selectedStandardFestivites.length > 0 && (
                    <div>
                      <span className="font-weight-bold">
                        {formatMessage(messages.standardFestivities)}:&nbsp;
                      </span>
                      <span>{selectedStandardFestivites.join(', ')}</span>
                    </div>
                  )}
                  {selectedCustomFestivites.length > 0 && (
                    <div>
                      <span className="font-weight-bold">
                        {formatMessage(messages.customFestivities)}:&nbsp;
                      </span>
                      <span>{selectedCustomFestivites.join(', ')}</span>
                    </div>
                  )}
                  {office.festivitiesMessage && (
                    <div>
                      <span className="font-weight-bold">
                        {formatMessage(messages.message)}:&nbsp;
                      </span>
                      <span>
                        {
                          createdMessages.find(
                            (item) => item.id === office.festivitiesMessage
                          )?.name
                        }
                      </span>
                    </div>
                  )}
                </Popover>
              </>
            )}
          </td>
          <td>
            {selectedOpening.filter((item) => item).length > 0 && (
              <>
                <span
                  onClick={this.toggleOpeningPopover}
                  id={`OpeningPopover_${office.route}`}
                >
                  <ToolboxIcon
                    name="file-view-1"
                    className="p-2"
                    style={{ top: '2px' }}
                  >
                    {formatMessage(messages.showOpening)}
                  </ToolboxIcon>
                </span>
                <Popover
                  isOpen={this.state.popoverOpeningOpen}
                  toggle={this.toggleOpeningPopover}
                  target={`OpeningPopover_${office.route}`}
                >
                  {selectedOpening.map((day, index) =>
                    day ? (
                      <div key={index}>
                        <span className="font-weight-bold mr-2">
                          {formatMessage(messages[index])}:
                        </span>
                        <span>{day}</span>
                      </div>
                    ) : (
                      <></>
                    )
                  )}
                  {office.openingMessage && (
                    <div>
                      <span className="font-weight-bold">
                        {formatMessage(messages.message)}:
                      </span>
                      <span>
                        {
                          createdMessages.find(
                            (item) => item.id === office.openingMessage
                          )?.name
                        }
                      </span>
                    </div>
                  )}
                </Popover>
              </>
            )}
          </td>
          {canEdit && (
            <span onClick={() => this.toggleEditModal()}>
              <ToolboxIcon name="pencil-write-2">
                {formatMessage(messages.editOffice)}
              </ToolboxIcon>
            </span>
          )}
        </tr>
        {this.state.modalEditOpen && (
          <PbxVirtualOperatorOfficeModalEdit
            isOpen={this.state.modalEditOpen}
            onClose={this.toggleEditModal}
            office={office}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    customerFestivities: getCalendarFestivitiesSettings(state),
    canEdit: hasGrants(state, 'PBX_HOLIDAYS_WRITE'),
    createdMessages: getVirtualOperatorMessages(state),
  };
}

export default injectIntl(
  connect(mapStateToProps)(PbxVirtualOperatorOfficeRow)
);

import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import FormSectionTitle from '../../../components/FormSectionTitle';
import Form from '../../../components/Form';
import Button from '../../../components/formControls/Button';
import ToastMessage from '../../../components/messages/ToastMessage';
import Input from '../../../components/formControls/Input';
import { saveWorkingBreakRequest } from '../../../js/workingPlans/actions';
import Label from '../../../components/formControls/Label';
import UsersSelect from '../../../components/formControls/UsersSelect';
import { FormGroup } from 'reactstrap';
import GroupsMembersShortcuts from '../../../components/GroupsMembersShortcuts';
import { getBreakWorkingHours, getBreakWorkingHoursError, getBreakWorkingHoursLoading, getBreakWorkingHoursSuccess } from '../../../js/workingPlans/selectors';

const messages = defineMessages({
  title: {
    id: 'WorkingHoursBreak.label.title',
    defaultMessage: 'Automatic worked time reduction',
  },
  reducedTime: {
    id: 'WorkingHoursBreak.label.reducedTime',
    defaultMessage: 'Subract from daily worked time',
  },
  minimumHours: {
    id: 'WorkingHoursBreak.label.minimumHours',
    defaultMessage: 'when worked at least',
  },
  users: {
    id: 'WorkingHoursBreak.label.users',
    defaultMessage: 'For these users',
  },
  missingField: {
    id: 'WorkingHoursBreak.label.missingField',
    defaultMessage: 'Missing field',
  },
  wrongFormat: {
    id: 'WorkingHoursBreak.label.wrongFormat',
    defaultMessage: 'It must be a number',
  },
  missingUsers: {
    id: 'WorkingHoursBreak.label.missingUsers',
    defaultMessage: 'Select at least an user for that value',
  },
  hours: {
    id: 'WorkingHoursBreak.label.hours',
    defaultMessage: 'hours',
  },
  minutes: {
    id: 'WorkingHoursBreak.label.minutes',
    defaultMessage: 'minutes',
  },
  save: {
    id: 'WorkingHoursBreak.button.save',
    defaultMessage: 'Save',
  },
  saveSuccess: {
    id: 'WorkingHoursBreak.saveSuccess',
    defaultMessage: 'Your data has been saved',
  },
  saveError: {
    id: 'WorkingHoursBreak.saveError',
    defaultMessage: 'An error occurred',
  },
});

class WorkingHoursBreak extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        reducedTime: 0,
        minimumHours: 0,
        minimumMinutes: 0,
      },
      errors: {},
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    const reducedTime = this.arrangeReducedTime();
    const minimumHours = this.arrangeMinimumHours();
    const minimumMinutes = this.arrangeMinimumMinutes();
    const reducedUsers = this.arrangeReducedUsers();
    const errors = {};
    if (reducedTime && isNaN(reducedTime)) {
      errors.reducedTime = this.props.intl.formatMessage(messages.wrongFormat);
    }
    if (minimumHours && isNaN(minimumHours)) {
      errors.minimumHours = this.props.intl.formatMessage(messages.wrongFormat);
    }
    if (minimumMinutes && isNaN(minimumMinutes)) {
      errors.minimumMinutes = this.props.intl.formatMessage(messages.wrongFormat);
    }
    if (reducedTime > 0 && !reducedUsers.length) {
      errors.reducedUsers = this.props.intl.formatMessage(messages.missingUsers);
    }
    if (Object.keys(errors).length) {
      this.setState({
        errors,
      });
      return;
    }
    this.props.save({
      reducedTime: reducedTime !== '' ? parseInt(reducedTime) : 0,
      minimumHours: minimumHours !== '' ? parseInt(minimumHours) : 0,
      minimumMinutes: minimumMinutes !== '' ? parseInt(minimumMinutes) : 0,
      reducedUsers,
    });
  };

  arrangeReducedTime = () => {
    return this.state.data.reducedTime !== 0
      ? this.state.data.reducedTime
      : this.props.workingBreak && this.props.workingBreak.reducedTime
      ? this.props.workingBreak.reducedTime
      : 0;
  };

  arrangeMinimumHours = () => {
    return this.state.data.minimumHours !== 0
      ? this.state.data.minimumHours
      : this.props.workingBreak && this.props.workingBreak.minimumHours
      ? this.props.workingBreak.minimumHours
      : 0;
  };

  arrangeMinimumMinutes = () => {
    return this.state.data.minimumMinutes !== 0
      ? this.state.data.minimumMinutes
      : this.props.workingBreak && this.props.workingBreak.minimumMinutes
      ? this.props.workingBreak.minimumMinutes
      : 0;
  };

  arrangeReducedUsers = () => {
    return this.state.data.reducedUsers
      ? this.state.data.reducedUsers
      : this.props.workingBreak && this.props.workingBreak.reducedUsers
      ? this.props.workingBreak.reducedUsers
      : [];
  };

  handleReducedTimeChange = (e) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        reducedTime: e.target.value,
      },
      errors: {},
    });
  };

  handleMinimumHoursChange = (e) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        minimumHours: e.target.value,
      },
      errors: {},
    });
  };

  handleMinimumMinutesChange = (e) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        minimumMinutes: e.target.value,
      },
      errors: {},
    });
  };

  handleUsersSelection = (selected) => {
    this.setState({
      data: {
        ...this.state.data,
        reducedUsers: selected,
      },
      errors: {},
    });
  };

  render() {
    const {
      saveError,
      saveSuccess,
      saveLoading,
      intl: { formatMessage },
    } = this.props;

    return (
      <>
        {saveError && (
          <ToastMessage
            type="danger"
            text={formatMessage(messages.saveError)}
          />
        )}
        {saveSuccess && (
          <ToastMessage
            type="success"
            text={formatMessage(messages.saveSuccess)}
          />
        )}
        <Form onSubmit={this.onSubmit} scrolling required={false}>
          <FormSectionTitle className="my-3">
            {formatMessage(messages.title)}
          </FormSectionTitle>
          <div className="form-row mt-4">
            <div className="col-12 custom-control custom-control-inline" style={{lineHeight: '35px'}}>
              <label htmlFor="reducedTime">{formatMessage(messages.reducedTime)}</label>
              <span className="ml-3">
                <Input
                  maxLength={2}
                  style={{ width: '50px' }}
                  type="text"
                  onChange={this.handleReducedTimeChange}
                  value={this.arrangeReducedTime()}
                  name="reducedTime"
                  id="reducedTime"
                  error={this.state.errors.reducedTime}
                />
              </span>
              <span className="ml-3">{formatMessage(messages.minutes)}</span>
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col-12 custom-control custom-control-inline" style={{lineHeight: '35px'}}>
              <label htmlFor="reducedTime">
                {formatMessage(messages.minimumHours)}
              </label>
              <span className="ml-3">
                <Input
                  maxLength={3}
                  style={{ width: '50px' }}
                  type="text"
                  onChange={this.handleMinimumHoursChange}
                  value={this.arrangeMinimumHours()}
                  name="minimumHours"
                  id="minimumHours"
                  error={this.state.errors.minimumHours}
                />
              </span>
              <span className="ml-3">{formatMessage(messages.hours)}</span>

              <span className="ml-3">
                <Input
                  maxLength={2}
                  style={{ width: '50px' }}
                  type="text"
                  onChange={this.handleMinimumMinutesChange}
                  value={this.arrangeMinimumMinutes()}
                  name="minimumMinutes"
                  id="minimumHMinutes"
                  error={this.state.errors.minimumMinutes}
                />
              </span>
              <span className="ml-3">{formatMessage(messages.minutes)}</span>
            </div>
          </div>
          <div className="form-row">
            <div className="col-xl-6">
              <Label for="users">
                {formatMessage(messages.users)}
              </Label>
              <UsersSelect
                isMulti
                isClearable
                onSelect={this.handleUsersSelection}
                selected={this.arrangeReducedUsers()}
                required
                error={this.state.errors.reducedUsers}
              />
            </div>
            <div className="col-xl-6 mt-xl-4">
              <FormGroup>
                <GroupsMembersShortcuts
                  includeMyself={false}
                  includeFavourites={false}
                  allowedContacts={false}
                  onSelect={(members) =>
                    this.handleUsersSelection(members)
                  }
                />
              </FormGroup>
            </div>
          </div>
          <div className="pt-3 text-center">
            <Button
              className="btn btn-primary"
              text={formatMessage(messages.save)}
              loading={saveLoading}
            />
          </div>
        </Form>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    saveLoading: getBreakWorkingHoursLoading(state),
    saveError: getBreakWorkingHoursError(state),
    saveSuccess: getBreakWorkingHoursSuccess(state),
    workingBreak: getBreakWorkingHours(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    save: saveWorkingBreakRequest,
  })(WorkingHoursBreak)
);

import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import SectionTitle from '../../../../components/SectionTitle';
import SecondaryNav from '../../../../components/SecondaryNav';

const messages = defineMessages({
  title: {
    id: 'PbxVirtualOperator.title',
    defaultMessage: 'Virtual operator',
  },
  customer: {
    id: 'PbxVirtualOperator.customer.navLabel',
    defaultMessage: 'Enterprise',
  },
  users: {
    id: 'PbxVirtualOperator.users.navLabel',
    defaultMessage: 'Users',
  },
  offices: {
    id: 'PbxVirtualOperator.offices.navLabel',
    defaultMessage: 'Offices',
  },
  messages: {
    id: 'PbxVirtualOperator.messages.navLabel',
    defaultMessage: 'Messages',
  },
  whatsappQuestions: {
    id: 'PbxVirtualOperator.whatsappQuestions.navLabel',
    defaultMessage: 'Whatsapp questions',
  },
});

const nav = [
  {
    id: 'PbxVirtualOperator.customer.linkLabel',
    href: '/pbx/virtual-operator/customer',
    label: messages.customer,
  },
  {
    id: 'PbxVirtualOperator.users.linkLabel',
    href: '/pbx/virtual-operator/users',
    label: messages.users,
  },
  {
    id: 'PbxVirtualOperator.offices.linkLabel',
    href: '/pbx/virtual-operator/offices',
    label: messages.offices,
  },
  {
    id: 'PbxVirtualOperator.messages.linkLabel',
    href: '/pbx/virtual-operator/messages',
    label: messages.messages,
  },
  {
    id: 'PbxVirtualOperator.whatsappQuestions.linkLabel',
    href: '/pbx/virtual-operator/whatsapp-questions',
    label: messages.whatsappQuestions,
  },
];

const PbxVirtualOperator = ({
  intl: { formatMessage },
  children,
  scrolling,
}) => (
  <div className="border rounded h-100 bg-white">
    <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
    <SecondaryNav tabs={nav} />
    <div
      className={
        scrolling ? 'yn-section-with-nav-scrolling' : 'yn-section-with-nav'
      }
    >
      {children}
    </div>
  </div>
);

export default injectIntl(PbxVirtualOperator);

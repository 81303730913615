import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import Button from '../../../components/formControls/Button';
import {
  isCustomFieldsEditing,
  isCustomFieldsDraft,
  getTreeQuestionsIds,
  getTreeId,
  getFetchTicketsTreeLoaded,
  getFetchTicketsTreeError,
  getSaveTicketsTreeLoaded,
  getSaveTicketsTreeError,
} from '../../../js/tickets/selectors';
import {
  toggleTreeEditMode,
  saveTicketsTreeRequest,
  fetchTicketsTreeRequest,
  deleteTicketsTreeRequest,
} from '../../../js/tickets/actions';
import TicketsFieldsEditQuestionModal from './TicketsFieldsEditQuestionModal';
import TicketsFieldsTreeQuestion from './TicketsFieldsTreeQuestion';
import LinkButton from '../../../components/LinkButton';
import Loading from '../../../components/Loading';
import { showConfirmModal } from '../../../js/confirm/actions';
import FormSectionTitle from '../../../components/FormSectionTitle';

const messages = defineMessages({
  title: {
    id: 'TicketsFieldsTree.title',
    defaultMessage: 'Questions',
  },
  fieldTitle: {
    id: 'TicketsFieldsTree.fieldTitle',
    defaultMessage: 'Title',
  },
  fieldDescription: {
    id: 'TicketsFieldsTree.fieldDescription',
    defaultMessage: 'Description',
  },
  customFieldsTree: {
    id: 'TicketsFieldsTree.customFieldsTree',
    defaultMessage: 'Current tree fields:',
  },
  customFieldsDraftTree: {
    id: 'TicketsFieldsTree.customFieldsDraftTree',
    defaultMessage: 'Draft tree fields:',
  },
  addQuestion: {
    id: 'TicketsFieldsTree.addQuestion',
    defaultMessage: 'Add a question',
  },
  draftModalText: {
    id: 'TicketsFieldsTree.draftModalText',
    defaultMessage:
      'This is a draft! Do you want to continue edit or delete it and view the current tree?',
  },
  startFromDraft: {
    id: 'TicketsFieldsTree.startFromDraft',
    defaultMessage: 'Continue from draft',
  },
  startFromActual: {
    id: 'TicketsFieldsTree.startFromActual',
    defaultMessage: 'Remove and view current',
  },
  cancel: {
    id: 'TicketsFieldsTree.cancel',
    defaultMessage: 'Remove draft',
  },
  save: {
    id: 'TicketsFieldsTree.save',
    defaultMessage: 'Save tree',
  },
  edit: {
    id: 'TicketsFieldsTree.edit',
    defaultMessage: 'Edit tree',
  },
  fieldContact: {
    id: 'TicketsFieldsTree.fieldContact',
    defaultMessage: 'Contact',
  },
  fieldAttachments: {
    id: 'TicketsFieldsTree.fieldAttachments',
    defaultMessage: 'Attachments',
  },
  fieldPriority: {
    id: 'TicketsFieldsTree.fieldPriority',
    defaultMessage: 'Priority',
  },
  fieldResolution: {
    id: 'TicketsFieldsTree.fieldResolution',
    defaultMessage: 'Resolution date',
  },
  fieldCategory: {
    id: 'TicketsFieldsTree.fieldCategory',
    defaultMessage: 'Category',
  },
  fieldAssignment: {
    id: 'TicketsFieldsTree.fieldAssignment',
    defaultMessage: 'Assign to',
  },
  noCustomField: {
    id: 'TicketsFieldsTree.noCustomField',
    defaultMessage: 'No custom field',
  },
  deleteConfirmText: {
    id: 'TicketsFieldsTree.deleteConfirmText',
    defaultMessage: 'Are you sure to remove this draft?',
  },
});
const style = {
  defaultFields: {
    //fontSize: '1.1rem'
    color: 'var(--gray)',
  },
  customFieldsTitle: {
    fontWeight: 'bold',
    //backgroundColor: 'var(--gray-light)',
  },
};

class TicketsFieldsTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionModalOpen: false,
      newQuestionOrder: 0,
    };
  }

  componentDidMount() {
    const { fetchTree } = this.props;
    fetchTree({ draft: true, choiceParent: null, whatsapp: this.props.whatsapp });
  }

  createTree = () => {
    const { saveTree } = this.props;
    saveTree({ isDraft: true, whatsapp: this.props.whatsapp });
    this.setEditing(true);
  };

  removeDraft = () => {
    const {
      confirm,
      deleteTree,
      treeId,
      whatsapp,
      intl: { formatMessage },
    } = this.props;

    confirm({
      message: formatMessage(messages.deleteConfirmText),
      modalAction: deleteTree,
      actionParams: {
        treeId,
        whatsapp,
      },
    });
  };

  saveTree = () => {
    const { saveTree } = this.props;
    saveTree({ isDraft: false, whatsapp: this.props.whatsapp });
    this.setEditing(false);
  };

  setEditing = (edit) => {
    const { toggleEditMode } = this.props;
    toggleEditMode(edit);
  };

  questionModalToggle = (order) => {
    const { questionModalOpen } = this.state;
    this.setState({
      questionModalOpen: !questionModalOpen,
      newQuestionOrder: order,
    });
  };

  render() {
    const {
      isEditing,
      questionsIds,
      treeId,
      isDraft,
      fetching,
      saving,
      whatsapp,
      intl: { formatMessage },
    } = this.props;
    const { questionModalOpen, newQuestionOrder } = this.state;

    return (
      <div className="border rounded p-3">
        {(fetching || saving) && <Loading />}
        <div className={fetching || saving ? 'd-none' : ''}>
          <FormSectionTitle className="mb-3">
            {formatMessage(messages.title)}
          </FormSectionTitle>
          <ul>
            {!whatsapp && (
              <>
                <li style={style.defaultFields}>
                  {formatMessage(messages.fieldTitle)}
                </li>
                <li style={style.defaultFields}>
                  {formatMessage(messages.fieldContact)}
                </li>
              </>
            )}
            <li>
              <span style={style.customFieldsTitle}>
                {isDraft
                  ? formatMessage(messages.customFieldsDraftTree)
                  : formatMessage(messages.customFieldsTree)}
              </span>
              <ul className="list-unstyled yn-tickets-tree">
                {questionsIds.length > 0 &&
                  questionsIds.map((id) => (
                    <TicketsFieldsTreeQuestion
                      key={id}
                      treeId={treeId}
                      questionId={id}
                      whatsapp={whatsapp}
                    />
                  ))}
                {questionsIds.length === 0 && !isEditing && (
                  <li>{formatMessage(messages.noCustomField)}</li>
                )}
                {isEditing && (
                  <li className="mb-1">
                    <LinkButton
                      onClick={() =>
                        this.questionModalToggle(questionsIds.length + 1)
                      }
                    >
                      {formatMessage(messages.addQuestion)}
                    </LinkButton>
                  </li>
                )}
              </ul>
            </li>
            {!whatsapp && (
              <>
                <li style={style.defaultFields}>
                  {formatMessage(messages.fieldDescription)}
                </li>
                <li style={style.defaultFields}>
                  {formatMessage(messages.fieldAttachments)}
                </li>
                <li style={style.defaultFields}>
                  {formatMessage(messages.fieldPriority)}
                </li>
                <li style={style.defaultFields}>
                  {formatMessage(messages.fieldResolution)}
                </li>
                <li style={style.defaultFields}>
                  {formatMessage(messages.fieldCategory)}
                </li>
                <li style={style.defaultFields}>
                  {formatMessage(messages.fieldAssignment)}
                </li>
              </>
            )}
          </ul>
          {!isEditing && !isDraft && (
            <div className="pt-3 text-center">
              <Button
                className="btn btn-primary"
                text={formatMessage(messages.edit)}
                onClick={this.createTree}
              />
            </div>
          )}
          {isEditing && (
            <div className="pt-3 text-center">
              <Button
                className="btn btn-primary mr-2"
                text={formatMessage(messages.save)}
                onClick={this.saveTree}
              />
              <Button
                className="btn btn-outline-primary"
                text={formatMessage(messages.cancel)}
                onClick={this.removeDraft}
              />
            </div>
          )}
          {!isEditing && isDraft && (
            <div className="alert alert-success  text-center mt-4">
              <div>{formatMessage(messages.draftModalText)}</div>
              <div className="mt-3">
                <Button
                  className="btn btn-primary mr-2"
                  text={formatMessage(messages.startFromDraft)}
                  onClick={() => this.setEditing(true)}
                />
                <Button
                  className="btn btn-primary"
                  text={formatMessage(messages.startFromActual)}
                  onClick={this.removeDraft}
                />
              </div>
            </div>
          )}
        </div>
        {questionModalOpen && (
          <TicketsFieldsEditQuestionModal
            isOpen={questionModalOpen}
            onClose={this.questionModalToggle}
            order={newQuestionOrder}
            whatsapp={whatsapp}
          />
        )}
      </div>
    );
  }
}

TicketsFieldsTree.propTypes = {
  whatsapp: PropTypes.bool,
};

TicketsFieldsTree.defaultProps = {
  whatsapp: false,
};

function mapStateToProps(state) {
  return {
    fetching: !getFetchTicketsTreeLoaded(state),
    errorFetching: getFetchTicketsTreeError(state),
    saving: !getSaveTicketsTreeLoaded(state),
    errorSaving: getSaveTicketsTreeError(state),
    treeId: getTreeId(state),
    isEditing: isCustomFieldsEditing(state),
    isDraft: isCustomFieldsDraft(state),
    questionsIds: getTreeQuestionsIds(state, 0),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    toggleEditMode: toggleTreeEditMode,
    fetchTree: fetchTicketsTreeRequest,
    saveTree: saveTicketsTreeRequest,
    deleteTree: deleteTicketsTreeRequest,
    confirm: showConfirmModal,
  })(TicketsFieldsTree)
);

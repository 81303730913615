import { Abilis } from './Abilis';
import { Nethesis } from './Nethesis';
import { PhoneEnums } from './PhoneUtils';

export class PbxManager {
  static pbx;

  static init = (supplier, pbxData, mainPhoneData, othersPhoneData, name) => {
    if (supplier === PhoneEnums.PbxType.ABILIS) {
      PbxManager.pbx = new Abilis({
        url: pbxData.url + (pbxData.port ? `:${pbxData.port}` : ''),
        pbxLen: pbxData.extensionsLength,
        pbxLine: pbxData.exitCode,
        pbxConfig: mainPhoneData.config,
        username: mainPhoneData.username,
        password: mainPhoneData.password,
        isSIP: mainPhoneData.port === PhoneEnums.Interfaces.SIP,
        number: mainPhoneData.number,
        queuePrefixes: pbxData.queuePrefixes,
        parkPrefix: pbxData.parkPrefix,
        extensionsLength: pbxData.extensionsLength,
      });
    } else {
      // check if there is a webrtc extension
      let others = othersPhoneData || [];
      others = others.filter(
        (otherExtension) => otherExtension.type === 'WEBRTC'
      );
      PbxManager.pbx = new Nethesis({
        name,
        sipPort: pbxData.sipPort,
        url: pbxData.url,
        pbxLine: pbxData.exitCode,
        mainUsername: mainPhoneData.username,
        mainPassword: mainPhoneData.password,
        mainNumber: mainPhoneData.number,
        webrtcUsername: others.length > 0 ? others[0].username : null,
        webrtcPassword: others.length > 0 ? others[0].password : null,
        webrtcNumber: others.length > 0 ? others[0].number : null,
        queuePrefixes: pbxData.queuePrefixes,
        ringGroups: pbxData.ringGroups || null,
      });
    }
  };

  static login = (timeout) => PbxManager.pbx.login(timeout);

  static logout = () => PbxManager.pbx.logout();

  static getInitialStatus = () => PbxManager.pbx.getInitialStatus();

  static getStatusChanges = () => PbxManager.pbx.getStatusChanges();

  static parseSingleExtensionData = (data) =>
    PbxManager.pbx.parseSingleExtensionData(data);

  static parseSingleParkingData = (data) =>
    PbxManager.pbx.parseSingleParkingData(data);

  static parseSingleQueueData = (data) =>
    PbxManager.pbx.parseSingleQueueData(data);

  static retrieveExitCode = () => PbxManager.pbx.retrieveExitCode();

  static toggleDND = (status) => PbxManager.pbx.toggleDND(status);

  static callNumber = (number) => PbxManager.pbx.callNumber(number);

  static hangupCall = (callId) => PbxManager.pbx.hangupCall(callId);

  static declineCall = (callId, incomingCalls) =>
    PbxManager.pbx.declineCall(callId, incomingCalls);

  static hangupChannel = (channel) => PbxManager.pbx.hangupChannel(channel);

  static hangupHoldCall = (callId) => PbxManager.pbx.hangupHoldCall(callId);

  static holdCall = (callId) => PbxManager.pbx.holdCall(callId);

  static unholdCall = (callId) => PbxManager.pbx.unholdCall(callId);

  static switchCalls = () => PbxManager.pbx.switchCalls();

  static attendedTransfer = (callId, to) =>
    PbxManager.pbx.attendedTransfer(callId, to);

  static blindTransfer = (callId, to) =>
    PbxManager.pbx.blindTransfer(callId, to);

  static blindTransferParking = (callId, to) =>
    PbxManager.pbx.blindTransferParking(callId, to);

  static queueTransfer = (queueId, to) =>
    PbxManager.pbx.queueTransfer(queueId, to);

  static queueAnswer = (queueId, queueName) =>
    PbxManager.pbx.queueAnswer(queueId, queueName);

  static parkAnswer = (queueId) => PbxManager.pbx.parkAnswer(queueId);

  static parkCall = (number, callId) => PbxManager.pbx.parkCall(number, callId);

  static pickupCall = (callId, calledNumber) =>
    PbxManager.pbx.pickupCall(callId, calledNumber);

  static linkCalls = (activeCallId) => PbxManager.pbx.linkCalls(activeCallId);

  static muteCall = (callId) => PbxManager.pbx.muteCall(callId);

  static unmuteCall = (callId) => PbxManager.pbx.unmuteCall(callId);

  static startConference = (number, callId) =>
    PbxManager.pbx.startConference(number, callId);

  static endConference = (conferenceId) =>
    PbxManager.pbx.endConference(conferenceId);

  static joinConference = () => PbxManager.pbx.joinConference();

  static hangupUserInConference = (extensionId, conferenceId) =>
    PbxManager.pbx.hangupUserInConference(extensionId, conferenceId);

  static muteUserInConference = (userId, conferenceId) =>
    PbxManager.pbx.muteUserInConference(userId, conferenceId);

  static unmuteUserInConference = (userId, conferenceId) =>
    PbxManager.pbx.unmuteUserInConference(userId, conferenceId);

  static retrieveConferenceData = (owner) =>
    PbxManager.pbx.retrieveConferenceData(owner);

  static recordCall = (callId, users) =>
    PbxManager.pbx.recordCall(callId, users);

  static stopRecordCall = (callId, users) =>
    PbxManager.pbx.stopRecordCall(callId, users);

  static spyCall = (callId, number) => PbxManager.pbx.spyCall(callId, number);

  static intrudeCall = (callId, number) =>
    PbxManager.pbx.intrudeCall(callId, number);

  static saveUserSetting = (rule) => PbxManager.pbx.saveUserSetting(rule);

  static retrieveUserSettings = (userId) =>
    PbxManager.pbx.retrieveUserSettings(userId);

  static deleteUserSetting = (rule) => PbxManager.pbx.deleteUserSetting(rule);

  static retrieveMeCdr = (filters, options) =>
    PbxManager.pbx.retrieveMeCdr(filters, options);

  static retrieveOthersCdr = (filters, options) =>
    PbxManager.pbx.retrieveOthersCdr(filters, options);

  static retrieveQueuesCdr = (filters, options) =>
    PbxManager.pbx.retrieveQueuesCdr(filters, options);

  static retrieveHotelCdr = (filters, options) =>
    PbxManager.pbx.retrieveHotelCdr(filters, options);

  static retrieveWakeups = (filters) => PbxManager.pbx.retrieveWakeups(filters);

  static saveWakeup = (data) => PbxManager.pbx.saveWakeup(data);

  static deleteWakeup = (id) => PbxManager.pbx.deleteWakeup(id);

  static retrieveWakeupAnswer = (filters) =>
    PbxManager.pbx.retrieveWakeupAnswer(filters);

  static loginWebsocket = () => PbxManager.pbx.loginWebsocket();

  static activateWebrtcPhone = (eventEmitter) =>
    PbxManager.pbx.activateWebrtcPhone(eventEmitter);

  static deactivateWebrtcPhone = () => PbxManager.pbx.deactivateWebrtcPhone();

  static closeWebrtc = () => PbxManager.pbx.closeWebrtc();

  static retrieveDefaultDevice = () => PbxManager.pbx.retrieveDefaultDevice();

  static answerCall = (callData) => PbxManager.pbx.answerCall(callData);

  static sendDtmf = (value) => PbxManager.pbx.sendDtmf(value);

  static retrievePbxSettings = (pbx) => PbxManager.pbx.retrievePbxSettings(pbx);

  static deletePbxSetting = (pbx, rule) =>
    PbxManager.pbx.deletePbxSetting(pbx, rule);

  static savePbxSetting = (pbx, rule) =>
    PbxManager.pbx.savePbxSetting(pbx, rule);

  static loadOwnVboxGreeting = () => PbxManager.pbx.loadOwnVboxGreeting();
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import Input from '../../../components/formControls/Input';
import Utils from '../../../js/lib/utils';
import Icon from '../../../components/icons/Icon';
import Label from '../../../components/formControls/Label';
import { type } from 'jquery';
import { TicketEnums } from '../../../js/tickets/TicketUtils';

const messages = defineMessages({
  choices: {
    id: 'TicketsFieldsEditChoicesList.choices',
    defaultMessage: 'Choices',
  },
  addChoice: {
    id: 'TicketsFieldsEditChoicesList.addChoice',
    defaultMessage: 'Add an option',
  },
  removeChoice: {
    id: 'TicketsFieldsEditChoicesList.removeChoice',
    defaultMessage: 'Remove this option',
  },
});

class TicketsFieldsEditChoicesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choices: undefined,
    };
  }

  handleChoice = (value, idx) => {
    const choices = this.arrangeChoices();
    choices.splice(idx, 1, { value });
    this.setState(
      {
        choices,
      },
      () => {
        this.onChange();
      }
    );
  };

  arrangeChoices = () =>
    Utils.getValorizedVariable(this.props.choices, this.state.choices, [
      { value: '' },
    ]);

  addChoice = (idx) => {
    const choices = this.arrangeChoices();
    choices.splice(idx, 0, { value: '' });
    this.setState(
      {
        choices,
      },
      () => {
        this.onChange();
      }
    );
  };

  removeChoice = (idx) => {
    const choices = this.arrangeChoices();
    choices.splice(idx, 1);
    this.setState(
      {
        choices,
      },
      () => {
        this.onChange();
      }
    );
  };

  onChange = () => {
    const { onChange } = this.props;
    onChange(this.arrangeChoices());
  };

  render() {
    const {
      error,
      isDisabled,
      whatsapp,
      type,
      intl: { formatMessage },
    } = this.props;
    return (
      <div className="row mt-2 p-2">
        <div className="col">
          <Label for="ticketType" mandatory>
            {formatMessage(messages.choices)}
          </Label>
          {!isDisabled &&
            this.arrangeChoices().map((choice, idx) => (
              <div className="row mb-1" key={idx}>
                <div className="col-10 pr-3">
                  <Input
                    type="text"
                    onChange={(e) => this.handleChoice(e.target.value, idx)}
                    value={choice.value}
                    name={`ticketChoice${idx}`}
                    id={`ticketChoice${idx}`}
                    error={error}
                    maxLength={whatsapp ? 30 : null}
                  />
                </div>
                <div className="col-2 px-0 pt-2">
                  {(!whatsapp || (type === TicketEnums.TicketQuestionTypes.RADIO && this.arrangeChoices().length <3)|| (type === TicketEnums.TicketQuestionTypes.SELECT && this.arrangeChoices().length <10)) && (
                    <span
                      className="mr-3"
                      onClick={() => this.addChoice(idx + 1)}
                    >
                      <Icon
                        name="add-plus"
                        width={16}
                        height={16}
                        color="var(--yn-blue-dark)"
                        tooltipPosition="right"
                      >
                        {formatMessage(messages.removeChoice)}
                      </Icon>
                    </span>
                  )}
                  {(idx > 0 ||
                    (idx === 0 && this.arrangeChoices().length > 1)) && (
                    <span onClick={() => this.removeChoice(idx)}>
                      <Icon
                        name="remove-minus"
                        width={16}
                        height={16}
                        color="var(--yn-blue-dark)"
                        tooltipPosition="right"
                      >
                        {formatMessage(messages.removeChoice)}
                      </Icon>
                    </span>
                  )}
                </div>
              </div>
            ))}
          {isDisabled && (
            <ul>
              {this.arrangeChoices().map((choice, idx) => (
                <li className="py-1" key={idx}>
                  {choice.value}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

TicketsFieldsEditChoicesList.propTypes = {
  choices: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  whatsapp: PropTypes.bool,
};

TicketsFieldsEditChoicesList.defaultProps = {
  isDisabled: false,
  error: '',
  whatsapp: false,
};

export default injectIntl(TicketsFieldsEditChoicesList);

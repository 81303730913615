import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import Icon from '../../../components/icons/Icon';
import {
  getTreeQuestionsById,
  getTreeQuestionsIds,
  getSaveTreeQuestionLoaded,
  isCustomFieldsEditing,
} from '../../../js/tickets/selectors';
import {
  fetchTicketsTreeRequest,
  deleteTreeChoiceRequest,
} from '../../../js/tickets/actions';
import TicketsFieldsTreeQuestion from './TicketsFieldsTreeQuestion';
import LinkButton from '../../../components/LinkButton';
import { showConfirmModal } from '../../../js/confirm/actions';
import TicketsFieldsEditChoiceModal from './TicketsFieldsEditChoiceModal';
import TicketsFieldsEditQuestionModal from './TicketsFieldsEditQuestionModal';

const messages = defineMessages({
  deleteConfirmText: {
    id: 'TicketsFieldsTreeChoice.deleteConfirmText',
    defaultMessage: 'Are you sure to remove this choice?',
  },
  addQuestion: {
    id: 'TicketsFieldsTreeChoice.addQuestion',
    defaultMessage: 'Add a question',
  },
  editChoice: {
    id: 'TicketsFieldsTreeChoice.editQuestion',
    defaultMessage: 'Edit choice',
  },
  removeChoice: {
    id: 'TicketsFieldsTreeChoice.removeChoice',
    defaultMessage: 'Remove choice',
  },
  linkQuestions: {
    id: 'TicketsFieldsTreeChoice.linkQuestions',
    defaultMessage: 'Link questions',
  },
});

class TicketsFieldsTreeChoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showChildren: false,
      choiceModalOpen: false,
      questionModalOpen: false,
    };
  }

  componentDidUpdate(prevprops) {
    const { savingQuestion, questionsIds } = this.props;
    if (
      prevprops.savingQuestion &&
      !savingQuestion &&
      questionsIds &&
      questionsIds.length
    ) {
      this.setState({
        showChildren: true,
      });
    }
  }

  showChildren = (show) => {
    const {
      fetchTree,
      whatsapp,
      choice: { choiceId },
    } = this.props;
    fetchTree({ draft: true, choiceParent: choiceId, whatsapp });
    this.setState({
      showChildren: show,
    });
  };

  toggleChildren = () => {
    if (this.props.element.collapseChildren) {
      this.props.fetchChildren(this.props.element.id);
    }
    this.props.toggleChildren(this.props.element.id);
  };

  choiceModalToggle = () => {
    const { choiceModalOpen } = this.state;
    this.setState({
      choiceModalOpen: !choiceModalOpen,
    });
  };

  questionModalToggle = () => {
    const { questionModalOpen } = this.state;
    this.setState({ questionModalOpen: !questionModalOpen });
  };

  handleDelete = () => {
    const {
      confirm,
      deleteChoice,
      questionId,
      treeId,
      choice,
      choiceParent,
      whatsapp,
      intl: { formatMessage },
    } = this.props;

    confirm({
      message: formatMessage(messages.deleteConfirmText),
      modalAction: deleteChoice,
      actionParams: {
        treeId,
        questionId,
        choiceId: choice.choiceId,
        choice,
        choiceParent,
        whatsapp,
      },
    });
  };

  render() {
    const {
      treeId,
      questionId,
      choice,
      showActions,
      questionsIds,
      choiceParent,
      whatsapp,
      intl: { formatMessage },
    } = this.props;
    const { showChildren, choiceModalOpen, questionModalOpen } = this.state;

    return (
      <li>
        {choice && (
          <>
            {(choice.hasChildren ||
              (questionsIds && questionsIds.length > 0)) &&
              (showChildren ? (
                <span onClick={() => this.showChildren(false)}>
                  <Icon name="arrow-down-8-filled" width={12} height={12} />
                </span>
              ) : (
                <span onClick={() => this.showChildren(true)}>
                  <Icon name="arrow-right-8-filled" width={12} height={12} />
                </span>
              ))}
            <span
              className="d-inline-block ml-1"
              style={{ position: 'relative', top: '-1px' }}
            >
              {choice.value}
            </span>
            {showActions && (
              <>
                <span className="ml-2" onClick={this.choiceModalToggle}>
                  <Icon
                    name="pencil-write-2"
                    color="var(--yn-blue-dark)"
                    width={20}
                    height={20}
                    style={{ top: '4px' }}
                  >
                    {formatMessage(messages.editChoice)}
                  </Icon>
                </span>
                {!choice.hasChildren && !questionsIds && (
                  <span className="ml-2" onClick={this.handleDelete}>
                    <Icon
                      name="bin-1"
                      color="var(--yn-blue-dark)"
                      width={20}
                      height={20}
                      style={{ top: '4px' }}
                    >
                      {formatMessage(messages.removeChoice)}
                    </Icon>
                  </span>
                )}
                {!choice.hasChildren && !questionsIds && (
                  <span className="ml-2" onClick={this.questionModalToggle}>
                    <Icon
                      name="link-2-filled"
                      color="var(--yn-blue-dark)"
                      width={20}
                      height={20}
                      style={{ top: '4px' }}
                    >
                      {formatMessage(messages.linkQuestions)}
                    </Icon>
                  </span>
                )}
              </>
            )}
            {showChildren && questionsIds && questionsIds.length > 0 && (
              <ul className="list-unstyled">
                {questionsIds.map((id) => (
                  <TicketsFieldsTreeQuestion
                    key={id}
                    treeId={treeId}
                    questionId={id}
                    choiceParent={choice.choiceId}
                    whatsapp={whatsapp}
                  />
                ))}

                {showActions && (
                  <li>
                    <LinkButton onClick={this.questionModalToggle}>
                      {formatMessage(messages.addQuestion)}
                    </LinkButton>
                  </li>
                )}
              </ul>
            )}
          </>
        )}
        {choiceModalOpen && (
          <TicketsFieldsEditChoiceModal
            isOpen={choiceModalOpen}
            onClose={this.choiceModalToggle}
            treeId={treeId}
            questionId={questionId}
            choice={choice.value}
            choiceId={choice.choiceId}
            choiceParent={choiceParent}
            whatsapp={whatsapp}
          />
        )}
        {questionModalOpen && (
          <TicketsFieldsEditQuestionModal
            isOpen={questionModalOpen}
            onClose={this.questionModalToggle}
            choiceParent={choice.choiceId}
            order={questionsIds ? questionsIds.length + 1 : 1}
            whatsapp={whatsapp}
          />
        )}
      </li>
    );
  }
}

TicketsFieldsTreeChoice.propTypes = {
  treeId: PropTypes.number.isRequired,
  questionId: PropTypes.number.isRequired,
  choice: PropTypes.object,
  choiceParent: PropTypes.number,
  whatsapp: PropTypes.bool,
};

TicketsFieldsTreeChoice.defaultProps = {
  choice: {},
  choiceParent: null,
  whatsapp: false,
};

function mapStateToProps(state, ownProps) {
  return {
    questionsById: getTreeQuestionsById(state),
    questionsIds: getTreeQuestionsIds(state, ownProps.choice.choiceId),
    savingQuestion: !getSaveTreeQuestionLoaded(state),
    showActions: isCustomFieldsEditing(state),
  };
}

function mapDispatchToProps() {
  return {
    fetchTree: fetchTicketsTreeRequest,
    deleteChoice: deleteTreeChoiceRequest,
    confirm: showConfirmModal,
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps())(TicketsFieldsTreeChoice)
);
